import React, { useState, useEffect, useRef } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import GridList from '@material-ui/core/GridList';
import AllInbox from '@material-ui/icons/AllInbox';
import styles from "assets/jss/material-dashboard-pro-react/views/allShipmentsStyle.js";
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { FixedSizeList } from 'react-window';
import { useCookies } from 'react-cookie';

import moment from 'moment';

const useStyles = makeStyles(styles);

export default function DetailView(props) {
  const classes = useStyles();
  const {airwayData} = props;
  const [status, setStatus] = useState({alert: "alert alert-info", icon: <InfoIcon ></InfoIcon>});
  const [loginCookies] = useCookies();  
  const [od, setOd] = useState('');

  React.useEffect(()=>{
    if (airwayData.origin_obj === null){
      airwayData.origin_obj = {name: '', address1: '', city: '', state: '', zip: ''}
    }
    if (airwayData.consignee_obj === null){
      airwayData.consignee_obj = {name: '', address1: '', city: '', state: '', zip: ''}
    }
    if (airwayData.hawb.status_text == 'Delivered'){
      setStatus({alert: 'alert alert-success', icon: <CheckCircleIcon></CheckCircleIcon> })
    }
    let odArr = []
    if (airwayData.hbox_obj.length){
      airwayData.hbox_obj.map(e =>{
        const o = e.session.split('-')[0]
        if (!odArr.includes(o)){
          odArr.push(o)
        }
      })
      setOd(odArr.join(', '))
    }
  },[])
  return (
    <>
    <GridContainer spacing={1}>
        <GridItem xs={10}>
          <div>
            <span className={classes.font1}>Status:</span> <strong style={{fontSize: '120%'}}>{airwayData.hawb.status_text}</strong>
          </div>
        </GridItem>
        <GridItem xs={12}>
          <div className={classes.titleDetailPane}>HAWB#: {airwayData.hawb.hawb}</div>
        </GridItem>
        <GridItem xs={4} md={4}>
          <span className={classes.font1}> Shipper Date:</span> {moment(airwayData.hawb.date).utc().format('MM/DD/YYYY')}
        </GridItem>
        <GridItem xs={4} md={4}>
          <span className={classes.font1}> Shipper Ref #:</span> {airwayData.hawb.ship_ref}
        </GridItem>
        <GridItem xs={4} md={4}>
          <span className={classes.font1}> Items Shipped:</span> {airwayData.hawb.qty}
        </GridItem>
        <GridItem xs={4} md={4}>
          <span className={classes.font1}> Units:</span> {airwayData.hawb.uom == 'BX' ? 'BOX(s)' : airwayData.hawb.uom}
        </GridItem>
        {
          loginCookies.customerId == 'ARMANI' && 
          <>
          <GridItem xs={4} md={4}>
            <span className={classes.font1}> OD #s:</span> {od}
          </GridItem>        
          </>
        }
        {
          loginCookies.customerId != 'ARMANI' && 
          <>
          <GridItem xs={4} md={4}>
            <span className={classes.font1}> Customer PO #:</span> {airwayData.hawb.cust_po}
          </GridItem>    
          </>
        }        
        <GridItem xs={4} md={4}>
          <span className={classes.font1}> Department#:</span> {airwayData.hawb.dept}
        </GridItem>
        <GridItem xs={4} md={4}>
          <span className={classes.font1}> Desc:</span> {airwayData.hawb.product}
        </GridItem>
        {
          loginCookies.customerId == 'ARMANI' && 
          <>
          <GridItem xs={4} md={4}>
            <span className={classes.font1}> Customer PO #:</span> {airwayData.hawb.cust_po}
          </GridItem> 
          </>
        }
        <br/><br/>
      </GridContainer>
      <GridContainer spacing={1}>
        <GridItem xs={4} md={4} >
          <span className={classes.font1}> Origin:</span> {airwayData.origin_obj.name} <br/>
          <span className={classes.font1}> Address:</span> {airwayData.origin_obj.address1} <br/>
          <span className={classes.font1}> City State Zip:</span> {airwayData.origin_obj.city+' '+airwayData.origin_obj.state+', '+airwayData.origin_obj.zip}
        </GridItem>
        <GridItem xs={4} md={4}>
          <span className={classes.font1}> Consignee:</span> {airwayData.consignee_obj.name} <br/>
          <span className={classes.font1}> Address:</span> {airwayData.consignee_obj.address1} <br/>
          <span className={classes.font1}> City State Zip:</span> {airwayData.consignee_obj.city+' '+airwayData.consignee_obj.state+', '+airwayData.consignee_obj.zip}
        </GridItem>
        <GridItem xs={4} md={4}>
          <span className={classes.font1}> Out for Delivery:</span> {(airwayData.hawb.ofd_date !== null) ? `${moment(airwayData.hawb.ofd_date).utc().format('MM/DD/YYYY')} @ ${airwayData.hawb.ofd_time}` : ''} <br/>
          <span className={classes.font1}> Delivered:</span> {(airwayData.hawb.pod_date !== null) ? `${moment(airwayData.hawb.pod_date).utc().format('MM/DD/YYYY')} @ ${airwayData.hawb.pod_time}` : ''} <br/>
          <span className={classes.font1}> Signed By:</span> {airwayData.hawb.pod_name}
        </GridItem>
      </GridContainer>
      {
        airwayData.hbox_obj.length ? (
      <div>
      <br/><br/>
      <span className={classes.font1}> Boxes:</span>
          <GridList cellHeight={100} cols={3}>
                {airwayData.hbox_obj.map((box,key)=>(
                    <ListItem key ={key}>
                        <ListItemIcon>
                        <AllInbox />
                        </ListItemIcon>
                        <ListItemText
                        primary={`Box Number: ${box.box_num}`}
                        secondary = {`${loginCookies.customerId == 'ARMANI' ? 'OD' : 'Session'} ${box.line + 1}: ${box.session}`}
                        />
                    </ListItem>
                ))}
          </GridList>
        </div>
        ) : (<></>)
      }
      </>
  );
}
