import React from "react";
// react component for creating dynamic tables
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomBootstrapInput from "components/BootstrapInput/BootstrapInput.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import axios from "axios";
import Paper from "@material-ui/core/Paper";
import { useCookies } from 'react-cookie';
import MaterialTable, { MTableBody, MTableHeader, MTableBodyRow} from 'material-table'
import DetailView from "views/DetailView/DetailView.js"
import DetailViewPrint from "views/DetailViewPrint/DetailViewPrint.js"

import DashboardIcon from "@material-ui/icons/Dashboard";
import UpArrow from "@material-ui/icons/ArrowDropUp"
import ReactToPrint from 'react-to-print';

import styles from "assets/jss/material-dashboard-pro-react/views/allShipmentsStyle.js";
import { get } from "http";

import moment from 'moment';

const NODE_URL = (process.env.REACT_APP_NODE_BASE_URL) ? process.env.REACT_APP_NODE_BASE_URL : '';

const useStyles = makeStyles(styles);

function ExpandedPanel(props) {
  const {rowData,classes} = props;
  const [loginCookies, setLoginCookies,removeLoginCookies] = useCookies();  
  const [airwayData, setAirwayData] = React.useState();
  const componentRef = React.useRef();

  React.useEffect(() => {
    if (!airwayData) {
      axios({
        url: NODE_URL + `/airway/get/${rowData['AIRWAY BILL']}`,
        method: 'get',
        headers: {
          'x-access-token': loginCookies.token
        }
      }).then(res => {
        if (res.data.success) {
          setAirwayData(res.data.results)
        }
      }).catch(e => console.log(e));
    }
  }, [])
  
  return (
    <div className={classes.wrapper}>
      <div 
         className={classes.detailsPane}
       >
        {airwayData &&
          <DetailView 
            classes = {classes}
            airwayData = {airwayData}
          />
        }   
     </div>
      <div style={{ display: "none" }} className={classes.wrapper}>
        <div 
           className={classes.detailsPane}
           ref={componentRef}
        >
          {airwayData &&
            <DetailViewPrint 
              classes = {classes}
              airwayData = {airwayData}
            />
          }   
        </div>
      </div>
      <ReactToPrint
        trigger={() => <Button style={{marginLeft:'30px'}} color="success" size="sm">Print this</Button>}
        content={() => componentRef.current}
  	    bodyClass={'RemoveOverflows'}
      />
    </div>
  )
}

export default function AllShipments(props) {
  const [tableData,setTableData] = React.useState({});
  const [loaded,setLoaded] = React.useState(false);
  const [selectedRow,setSelectedRow] = React.useState();
  const [hawb, setHawb] = React.useState('');

  const [searchAll, setSearchAll] = React.useState(
    {
      ['HAWB#']:'',
      ['Session ID']: '',
      ['Shipper Ref#']: '',
      ['Diesel Box Number']: '',
      ['ORIGIN']: '',
      ['Destination']: '',
      ['Start Date']: '',
      ['End Date']: '', 
      ['Shipping Method']: '',
      ['POD Date']: '',
      ['Excel Format']: '',
      
    }
  );

  const [loginCookies, setLoginCookies,removeLoginCookies] = useCookies();  
  const tableRef = React.createRef();

    React.useEffect(()=>{
      let url = `${NODE_URL}/airway/search`
      let hawb = props.match.params.hawb
      if (props.match.params.hawb !== undefined){
        setHawb(props.match.params.hawb)
        url = `${NODE_URL}/airway/search?hawb=${hawb}`
      }
      
      axios({
        url: url,
        method: 'post',
        headers: {'x-access-token':loginCookies.token}
        
      }).then(res=>{
          
          if (res.data.results){
            for (var a=0; a<res.data.results.length; a++){
              for (var key in res.data.results[a]){
                switch (key){
                  case 'SHIP DATE':
                  res.data.results[a][key] = moment(res.data.results[a][key]).utc().format('MM/DD/YYYY');
                  break;
                  case 'ETA DATE':
                  res.data.results[a][key] = (res.data.results[a][key] !== null) ? moment(res.data.results[a][key]).utc().format('ddd MM/DD/YYYY') : '';
                  break;
                  case 'DELIVERED':
                  res.data.results[a][key] = (res.data.results[a][key] !== null) ? `${moment(res.data.results[a][key]).utc().format('MM/DD/YYYY')}` : '';
                  break;
                }
                }
            }
          }
          setTableData(res.data);
  
      }).catch(e=>console.log(e));
      
    },[])

    React.useEffect(()=>{
      let url = `${NODE_URL}/airway/search`
      let hawb = props.match.params.hawb
      if (props.match.params.hawb !== undefined){
        setHawb(props.match.params.hawb)
        url = `${NODE_URL}/airway/search?hawb=${hawb}`
        
        axios({
          url: url,
          method: 'post',
          headers: {'x-access-token':loginCookies.token}
          
        }).then(res=>{
            axios({
            url: NODE_URL + `/airway/get/${res.data.results.data[0]['AIRWAY BILL']}`,
            method: 'get',
            headers: {
              'x-access-token': loginCookies.token
            }
          }).then(res => {
              tableRef.current.onToggleDetailPanel([0], tableRef.current.props.detailPanel);
          }).catch(e => console.log(e));

        }).catch(e=>console.log(e));
      }
    })
    
  const classes = useStyles();

  const getDetails = (event, rowData, togglePanel) => {
    togglePanel();   
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Track Shipment</h4>
          </CardHeader>
          <CardBody>
            <div>
            <MaterialTable
              tableRef={tableRef}
              columns={[
                {
                  title: "Airway Bill",
                  field: "AIRWAY BILL"
                },
                {
                  title: "Ship Date",
                  field: "SHIP DATE",
                  filtering: false
                },
                {
                  title: "Shipper #",
                  field: "SHIPPER NUMBER"
                },
                {
                  title: "Origin",
                  field: "ORIGIN"
                },
                {
                  title: "Destination",
                  field: "DESTINATION",
                
                },
                {
                  title: "ETA Date",
                  field: "ETA DATE",
                  filtering: false
                },
                {
                  title: "Status",
                  field: "STATUS",
                },
                {
                  title: "Delivered",
                  field: "DELIVERED",
                  filtering: false
                },
              ]}
              data={query =>
                new Promise((resolve, reject) => {

                  let url = `${NODE_URL}/airway/search`
                  let hawb = props.match.params.hawb
                  if (props.match.params.hawb !== undefined){
                    setHawb(props.match.params.hawb)
                    url = `${NODE_URL}/airway/search?hawb=${hawb}`
                  }

                  let sort = {};
                  if (query.orderBy){
                    sort = {[query.orderBy.field]:query.orderDirection==='desc'?1:-1}
                  }
                  axios({
                    url: url,
                    method: 'post',
                    headers: {
                      'x-access-token': loginCookies.token
                    },
                    data: {
                      ...searchAll,
                      pageSize:query.pageSize,
                      page: query.page,
                      filters: query.filters,
                      sort

                    },
                  })
                  .then((result) => {
                    if (result.data.success) {
                      let dt = result.data.results.data;
                      for (var a = 0; a < dt.length; a++) {
                        dt[a]['SHIP DATE'] = moment(dt[a]['SHIP DATE']).utc().format('MM/DD/YYYY');
                        dt[a]['ETA DATE'] = dt[a]['ETA DATE']!==null? moment(dt[a]['ETA DATE']).utc().format('ddd MM/DD/YYYY'):null;
                        dt[a]['DELIVERED'] = dt[a]['DELIVERED']!==null? moment(dt[a]['DELIVERED']).utc().format('MM/DD/YYYY'):null;

                      }
                      resolve({
                        data: dt,
                        page: result.data.results ? result.data.results.page : undefined,
                        totalCount: result.data.results ? result.data.results.total : undefined,
                      })
                    } else {
                      resolve({
                        data: [] ,
                        page: 0  ,
                        totalCount: 0,
                      })
                    }
                  })
                })
              }              
              title=""
              detailPanel={rowData => {

                return (
                  <ExpandedPanel 
                    rowData = {rowData} 
                    classes={classes}/>

                    
                    )
              }}
              onRowClick={(event, rowData, togglePanel) => {getDetails(event, rowData, togglePanel)}}
              options={{
                toolbar:false,
                search:false,
                filtering: true,
                headerStyle:{
                  fontSize: '1rem',
                  fontFamily: 'Roboto',
                  fontWeight: 300
                },
                hideFilterIcons: true,
                pageSize: 10, 
              }}
              
              components={{
                Body: props => (
                    <MTableBody  {...props}/>
                ),
                Header: props=>(
                    <MTableHeader {...props}/>
                ),
                Container: props=>(
                    <Paper {...props} elevation={0}/>
                ),
              }}
              icons={{
                SortArrow: UpArrow
              }}
            />
              
            </div>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
