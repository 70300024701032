import 'isomorphic-fetch';
import React, { useState, useEffect, useRef } from "react";
// react component for creating dynamic tables
import ReactTable from "react-table-v6";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import AddAlert from "@material-ui/icons/AddAlert";
import AddCircle from "@material-ui/icons/AddCircle";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomSelect from "components/CustomSelect/CustomSelect2.js";
import Alert from 'react-bootstrap/Alert';
import Autocomplete from 'react-autocomplete';

import { useInput } from './hooks';

import axios from "axios";
import moment from 'moment';

import { useCookies } from 'react-cookie';

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

const NODE_URL = (process.env.REACT_APP_NODE_BASE_URL) ? process.env.REACT_APP_NODE_BASE_URL : '';

export default function UsersList() {
  const [loginCookies, setLoginCookies,removeLoginCookies] = useCookies();  
  const [alert, setAlert] = useState({open: false, color: 'danger', icon: AddAlert, message: ''});
  const [notice, setNotice] = useState({show: false, contents: <span></span>})
  const [dialog, setDialog] = useState({open: false, contents: <span></span>, maxWidth: 'sm'});
  const [formDialog, setFormDialog] = useState({open: false, title: 'Add User', key: '', button: 'Add User'});
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [table, setTable ] = useState({data: [], pages: -1, loading: true});

  const { value: _customerId, setValue: setCustomerId, bind:bindCustomerId, reset:resetCustomerId } = useInput('');
  const { value: _name, setValue: setName, bind:bindName, reset:resetName } = useInput('');
  const { value: _email, setValue: setEmail, bind:bindEmail, reset:resetEmail } = useInput('');
  const { value: _password, setValue: setPassword, bind:bindPassword, reset:resetPassword } = useInput('');
  const { value: _consignee, setValue: setConsignee, bind:bindConsignee, reset:resetConsignee } = useInput('');
  const { value: _customer, setValue: setCustomer, bind:bindCustomer, reset:resetCustomer } = useInput('');
  const { value: _role, setValue: setRole, bind:bindRole, reset:resetRole } = useInput('');
  const [consignees, setConsignees] = useState([]);
  const [customers, setCustomers] = useState([]);

  const reactTable = useRef();
  const classes = useStyles();

  const roles = [
    'admin', 'user', 'superadmin'
  ];

  const handleClose = () => {
    setDialog({open: false, contents: <span></span>,  maxWidth: 'sm'});
  };

  const handleFormClose = () => {
    resetCustomerId()
    resetName()
    resetEmail()
    resetPassword()
    resetCustomer()
    resetConsignee()
    resetRole()
    setFormDialog({open: false, title: 'Add User', key: '', button: 'Add User'});
  };

  const displayErrors = (errors) =>{
    setNotice({show: true, contents: errors.reduce((acc, x) => acc === null ? [x] : [acc, <br key="1" />, x], null)});
  }

  const confirmUpdate = (key) => {
    setBtnDisabled(true);
    setNotice({show: false, contents: <span></span>});
    setAlert({open: false, color: alert.color, icon: AddAlert, message: ''});

    let url = NODE_URL + `/user/create`;
    let isNew = true;
    let errors = [];

    if (key != ''){
      isNew = false;
      url = NODE_URL + `/user/update_by_id/${key}`;
    }

    if (_customerId == '')
      errors.push('Customer ID is required.');
    if (_email != '' && !_email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i))
      errors.push('Invalid Email format.');

    if (isNew){
      if (_password.length < 5){
        errors.push('Password need to be at least 5 characters.')
      }
    }
    else {
      if (_password != '' && _password.length < 5){
        errors.push('Password need to be at least 5 characters.')
      }
    }

    if (_consignee == '')
      errors.push('Consignee is required.');
    if (_role == '')
      errors.push('Role is required.');

    if (errors.length){
      displayErrors(errors);
      setBtnDisabled(false);
    }
    else{
      axios({
        url: url,
        method: 'post',
        data:{
          customerId: _customerId,
          name: _name,
          email: _email,
          customer: _customer,
          consignee: _consignee,
          password: _password,
          role: _role
        },
        headers: {
          'x-access-token': loginCookies.token
        }      
      })
      .then((res) => {
        if (res.data.success){
          setAlert({open: true, color: 'success', icon: AddAlert, message: res.data.message}); 
          reactTable.current && reactTable.current.fireFetchData();
        }
        else{
          res.data.message.map(err =>{
            errors.push(err);
          });
          displayErrors(errors);
        }
        setBtnDisabled(false);
      })
      .catch(err =>{
        
        setAlert({open: true, color: 'danger', icon: AddAlert, message: 'Connection error. Please reload the page.'})
      })
    }
  }
  
  const addNew = () =>{
    setNotice({show: false, contents: <span></span>})
    setFormDialog({open:true, title: 'Add New User', key: '', button: 'Add User'})   
  };

  const editData = async (key) =>{
    setNotice({show: false, contents: <span></span>})
    await axios({
      url: NODE_URL + `/user/get_by_id/${key}`,
      method: 'get',
      headers: {
        'x-access-token': loginCookies.token
      }      
    })
    .then((res) => {
      if (res.data.success){
        (async () => {
          let user = res.data.results
          setCustomerId(user.customerId)
          setName(user.name)
          setEmail(user.email)
          setCustomer(user.customer)
          setConsignee(user.consignee)
          setRole(user.role)

          await loadAutocomplete('consignee', true, user.consignee);
          await loadAutocomplete('customer', true, user.customer);
          setFormDialog({open:true, title: 'Edit User: ' + user.customerId, key: key, button: 'Update'})   
        })();
      }
      else{
        setAlert({open: true, color: 'danger', icon: AddAlert, message: res.data.message});
      }
    })
    .catch(err =>{
      
      setAlert({open: true, color: 'danger', icon: AddAlert, message: 'Connection error. Please reload the page.'})
    })
  }

  const confirmDelete = async(key) =>{
    handleClose(false);
    axios({
      url: NODE_URL + `/user/delete_by_id/${key}`,
      method: 'post',
      headers: {
        'x-access-token': loginCookies.token
      }      
    })
    .then((res) => {
      if (res.data.success){
        setAlert({open: true, color: 'success', icon: AddAlert, message: res.data.message});
        reactTable.current && reactTable.current.fireFetchData();
      }
      else{
        setAlert({open: true, color: 'danger', icon: AddAlert, message: res.data.message});
      }
    })
    .catch(err =>{
      
      setAlert({open: true, color: 'danger', icon: AddAlert, message: 'Connection error. Please reload the page.'})
    })
  }
  const confirm = async (key) =>{
    setDialog({
      open: true,
      maxWidth: 'sm',
      contents: <><DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete this User?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} color="white">
          Cancel
        </Button>
        <Button onClick={() => confirmDelete(key)} color="primary">
          Confirm
        </Button>
      </DialogActions>
      </>
    });
  };

  const autocompleteChange = async (type, val) =>{
    
    if (type == 'customer')
      setCustomer(val.toUpperCase())
    else
      setConsignee(val.toUpperCase())
      
    await loadAutocomplete(type, true, val);
  }

  const loadAutocomplete = async (type, active, val) =>{
    (async () => {
      await axios({
        url: NODE_URL + `/user/list/${type}?val=${val}`,
        method: 'get',
        headers: {
          'x-access-token': loginCookies.token
        }      
      })
      .then((res) => {
        if (res.data.success && active){
          if (type == 'customer')
            setCustomers(res.data.results)
          else
            setConsignees(res.data.results);
        }
        else{
          setAlert({open: true, color: 'danger', icon: AddAlert, message: res.data.message});
        }
      })
      .catch(err =>{
        
        setAlert({open: true, color: 'danger', icon: AddAlert, message: 'Connection error. Please reload the page.'})
      })
    })();
  }

  useEffect(() => {
    let active = true;

    (async () => {
      await loadAutocomplete('consignee', active, '');
      await loadAutocomplete('customer', active, '');
    })();

    return () => {
      active = false;
    };
  }, []);

  useEffect(()=>{
    const timer = setTimeout(() => setAlert({open: false, color: alert.color, icon: AddAlert, message: ''}), 4000);
    return () => clearTimeout(timer);
  });

  return (
    <div>
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Users List</h4>
          </CardHeader>
          <CardBody>
            <div
              style={{textAlign: 'right'}}
            >
              <Button
                color="primary"
                onClick={() => {addNew()}}
               > <AddCircle /> Add New
              </Button>
            </div>
            <ReactTable
              ref={reactTable}
              filterable
              columns={[
                {
                  Header: "ID",
                  accessor: "_id",
                  sortable: false,
                  filterable: false,
                  show: false            
                },
                {
                  Header: "Customer ID",
                  accessor: "customerId"
                },
                {
                  Header: "Name",
                  accessor: "name"
                },
                {
                  Header: "Email",
                  accessor: "email"
                },
                {
                  Header: "Customer",
                  accessor: "customer"
                },
                {
                  Header: "Consignee",
                  accessor: "consignee"
                },
                {
                  Header: "Role",
                  accessor: "role"
                },
                {
                  Header: "Date",
                  accessor: "datecreated",
                  filterable: false
                },
                {
                  Header: "Actions",
                  accessor: "actions",
                  sortable: false,
                  filterable: false
                }
              ]}
              defaultPageSize={10}
              showPaginationTop={false}
              showPaginationBottom={true}
              className="-striped -highlight"
              data={table.data} 
              pages={table.pages} 
              loading={table.loading}
              manual // informs React Table that you'll be handling sorting and pagination server-side
              onFetchData={(state, instance) => {
                setTable({data: state.data, pages: state.pages, loading:true })

                // fetch your data
                axios({
                  url: NODE_URL + `/user/list`,
                  method: 'post',
                  headers: {
                    'x-access-token': loginCookies.token
                  },
                  data: {
                    page: state.page,
                    pageSize: state.pageSize,
                    sorted: state.sorted,
                    filtered: state.filtered                   
                  }    
                })
                .then((res) => {
                  if (res.data.success){ 
                    setTable({data:           
                      res.data.results.map((prop, key) => {
                      return {
                        _id: prop._id,
                        customerId: prop.customerId,
                        name: prop.name,
                        email: prop.email,
                        customer: prop.customer,
                        consignee: prop.consignee,
                        role: prop.role,
                        datecreated: moment(prop.datecreated).utc().format('MM/DD/YYYY'),
                        actions: (
                          <div className="actions-right">
                            <Button
                              justIcon
                              round
                              simple
                              onClick={() => { editData(prop._id) }}
                              color="info"
                              className="edit"
                            >
                              <Edit />
                            </Button>
                            <Button
                              justIcon
                              round
                              simple
                              onClick={() => {
                                confirm(prop._id);
                              }}
                              color="danger"
                              className="remove"
                            >
                              <Close />
                            </Button>
                          </div>
                        )
                      };
                    }), pages: res.data.pages, loading: false })
                  }
                });
              }}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
    <Dialog
        fullWidth={true}
        open={formDialog.open}
        onClose={() => handleFormClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{formDialog.title}</DialogTitle>
          <DialogContent>
          <form>
            <Alert 
              variant={"danger"}
              show={notice.show}
            >
              {notice.contents}
            </Alert>
            <CustomInput
              labelText="Customer ID"
              id="customerId"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                type: "text",
                autoComplete: "off"
              }}
              {...bindCustomerId} 
            />
            <CustomInput
              labelText="Name"
              id="name"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                type: "text"
              }}
              {...bindName} 
            />
            <CustomInput
              labelText="Email adress"
              id="email"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                type: "email"
              }}
              {...bindEmail} 
            />
            <CustomInput
              labelText="Password"
              id="password"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                type: "password",
                autoComplete: "off"
              }}
              {...bindPassword} 
            />
            <Autocomplete
              getItemValue={(item) => item.name}
              items={customers}
              menuStyle={{zIndex: 999, position: 'absolute', width: '100%', left: 0, top: 60, minWidth: '100%', border: '1px solid #AAAAAA', borderTop: '0px'}}
              wrapperStyle={{position: 'relative', display: 'block', width: '100%'}}
              renderItem={(item, isHighlighted) =>
                <div style={{ background: isHighlighted ? 'lightgray' : 'white', padding: '2px 5px' }}
                  key={item._id}  
                >
                  {item.name}
                </div>
              }
              value={_customer}
              onChange={(e) => autocompleteChange('customer',e.target.value)}
              onSelect={(val) => setCustomer(val)}
              renderInput={(props) =>
                <CustomInput
                labelText="Customer"
                id="customer"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{...props}}
              />
              }
            />
            <Autocomplete
              getItemValue={(item) => item.name}
              items={consignees}
              menuStyle={{zIndex: 999, position: 'absolute', width: '100%', left: 0, top: 60, minWidth: '100%', border: '1px solid #AAAAAA', borderTop: '0px'}}
              wrapperStyle={{position: 'relative', display: 'block', width: '100%'}}
              renderItem={(item, isHighlighted) =>
                <div style={{ background: isHighlighted ? 'lightgray' : 'white', padding: '2px 5px' }}
                  key={item._id}  
                >
                  {item.name}
                </div>
              }
              value={_consignee}
              onChange={(e) => autocompleteChange('consignee',e.target.value)}
              onSelect={(val) => setConsignee(val)}
              renderInput={(props) =>
                <CustomInput
                labelText="Consignee"
                id="consignee"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{...props}}
              />
              }
            />
              <CustomSelect
                label={'Role'} 
                id='role'
                dropdownList = {roles}
                varian="standard"
                {...bindRole} 
              />
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleFormClose(false)} color="white">
              Cancel
            </Button>
            <Button 
              onClick={() => confirmUpdate(formDialog.key)} 
              color="primary"
              disabled={btnDisabled}
            >
              {formDialog.button}
            </Button>
          </DialogActions>
      </Dialog>
    <Dialog
        fullWidth={true}
        open={dialog.open}
        onClose={() => handleClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {dialog.contents}
      </Dialog>
    <Snackbar
    place="tc"
    open={alert.open}
    color={alert.color ? alert.color : 'danger'}
    icon={alert.icon}
    close
    ContentProps={{
      'aria-describedby': 'message-id',
    }}
    message={<span id="message-id">{alert.message}</span>}
    closeNotification={() => setAlert({open:false, color: alert.color})}
  />
  </div>
  );
}
