import Buttons from "views/Components/Buttons.js";
import Calendar from "views/Calendar/Calendar.js";
import Charts from "views/Charts/Charts.js";
import Dashboard from "views/Dashboard/Dashboard.js";
import ErrorPage from "views/Pages/ErrorPage.js";
import ExtendedForms from "views/Forms/ExtendedForms.js";
import ExtendedTables from "views/Tables/ExtendedTables.js";
import FullScreenMap from "views/Maps/FullScreenMap.js";
import GoogleMaps from "views/Maps/GoogleMaps.js";
import GridSystem from "views/Components/GridSystem.js";
import Icons from "views/Components/Icons.js";
import LockScreenPage from "views/Pages/LockScreenPage.js";
import LoginPage from "views/Pages/LoginPage.js";
import SearchView from "views/SearchView/SearchView.js";
import AllShipments from 'views/AllShipments/AllShipments.js';
import Notifications from "views/Components/Notifications.js";
import Panels from "views/Components/Panels.js";
import PricingPage from "views/Pages/PricingPage.js";
import RTLSupport from "views/Pages/RTLSupport.js";
//import ReactTables from "views/Tables/ReactTables.js";
import SearchHAWB from "views/Pages/SearchHAWB.js"
import RegisterPage from "views/Pages/RegisterPage.js";
import ShipmentTable from "views/ShipmentTable/ShipmentTable.js"
import Profile from "views/Profile/Profile.js"
import Logout from "views/Logout/Logout.js"
import UsersList from "views/UsersList/UsersList.js"
import SignUp from 'views/SignUp/SignUp.js';

import DashboardIcon from "@material-ui/icons/Dashboard";
import HomeIcon from "@material-ui/icons/Home";
import LocalAirportIcon from "@material-ui/icons/LocalAirport";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import EmailIcon from "@material-ui/icons/Email";
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';

var dashRoutes = [
   /* 
   {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin"
  },
  {
    path: "/track",
    name: "Track Shipment",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: ReactTables,
    layout: "/admin",

  },
  */
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
    layout: "/auth",
    redirect: true
  },
  /*
  {
    path: "/search-hawb",
    name: "Search Airway Bill",
    component: SearchHAWB,
    layout: "/auth",
    redirect: true
  },
  */
  {
    path: "/register-page",
    name: "Register",
    component: RegisterPage,
    layout: "/auth",
    redirect: true
  },
  {
    path: "/home",
    name: "Home",
    component: () => { window.location = 'https://natroute.com/'; return null;}, 
    layout: "/admin",
    icon: HomeIcon,
  },
  {
    path: "/track",
    name: "Track Shipment",
    component: SearchView,
    layout: "/admin",
    icon: LocalShippingIcon,
  },
  {
    path: "/hawb/:hawb",
    name: "Track Shipment",
    component: AllShipments,
    layout: "/admin",
    params: 'hawb',
    redirect: true
  },
  {
    path: "/all-shipments",
    name: "All Shipments",
    component: AllShipments,
    layout: "/admin",
    icon: LocalShippingIcon,
  },
  {
    path: "/services",
    name: "Services",
    component: () => { window.location = 'https://natroute.com/services/'; return null;}, 
    layout: "/admin",
    icon: LocalAirportIcon,
  },
  {
    path: "/request-a-quote",
    name: "Request a Quote",
    component: () => { window.location = 'https://natroute.com/request-a-quote/'; return null;}, 
    layout: "/admin",
    icon: FormatQuoteIcon,
  },
  {
    path: "/sign-up",
    name: " Sign-up for POD Emails",
    component: SignUp,
    layout: "/admin",
    icon: AlternateEmailIcon,
  },
  {
    path: "/contact-us",
    name: "Contact Us",
    component: () => { window.location = 'https://natroute.com/contact-us/'; return null;}, 
    layout: "/admin",
    icon: EmailIcon,
  },
  {
    path: "/track-shipments",
    name: "Shipments Table",
    component: ShipmentTable,
    layout: "/admin",
    redirect: true
  },
  /*
   {
    path: "/profile",
    name: "Profile",
    component: Profile,
    layout: "/admin",
    redirect: true
  },
  */
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
    layout: "/admin",
    redirect: true
  }, 
  /*
  {
    path: "/users",
    name: "Users",
    component: UsersList,
    layout: "/admin",
    redirect: true
  },  
  */ 
 
];
export default dashRoutes;
