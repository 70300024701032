import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    width:'100%',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

export default function CustomizedSelects(props) {
  const classes = useStyles();
  const [age, setAge] = React.useState('');

  return (
      <FormControl style={{width:'100%'}}>
        <InputLabel shrink htmlFor="bootstrap-input"
			style={{fontSize: '14px', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', top: '10px', marginBottom: '10px', display: 'block'}}
		>{props.label}</InputLabel>
        <Select
          id={props.id}
          value={props.value}
          onChange={props.onChange}
          fullWidth={true}
          input={<BootstrapInput />}
		  variant='standard'
        >
          {props.dropdownList.map((prop,key)=>{
			const value = (prop.value !== undefined) ? prop.value : prop;
			const label = (prop.label !== undefined) ? prop.label : prop;
            return (
              <MenuItem
                key={key}
                value={value}
              >
                {label}
              </MenuItem>
            )
          })}
          
        </Select>
      </FormControl>
  );
}