import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AllInbox from '@material-ui/icons/AllInbox';
import styles from "assets/jss/material-dashboard-pro-react/views/allShipmentsStyle.js";
import { FixedSizeList } from 'react-window';

import moment from 'moment';

const useStyles = makeStyles(styles);

export default function DetailViewNoBoxes(props) {
  const classes = useStyles();
  const {airwayData} = props;

  React.useEffect(()=>{
    
  },[])
  return (
    <>
    <GridContainer spacing={1}>
        <GridItem xs={12}>
          <div className={classes.titleDetailPane}>HAWB#: {airwayData.hawb.hawb}</div>
        </GridItem>
        <GridItem xs={6} md={3}>
          <span className={classes.font1}> Shipper Date:</span> {moment(airwayData.hawb.date).utc().format('MM/DD/YYYY')}
        </GridItem>
        <GridItem xs={6} md={3}>
          <span className={classes.font1}> Shipper Ref #:</span> {airwayData.hawb.ship_ref}
        </GridItem>
        <GridItem xs={6} md={3}>
          <span className={classes.font1}> Items Shipped: {airwayData.hbox_count}</span> 
        </GridItem>
        <GridItem xs={6} md={3}>
          <span className={classes.font1}> Units:</span> BOX(s)
        </GridItem>
        <GridItem xs={6} md={3}>
          <span className={classes.font1}> Customer PO #:</span> {airwayData.hawb.cust_po}
        </GridItem>
        <GridItem xs={6} md={3}>
          <span className={classes.font1}> Department#:</span> {airwayData.hawb.dept}
        </GridItem>
        <GridItem xs={6} md={3}>
          <span className={classes.font1}> Desc:</span> {airwayData.hawb.product}
        </GridItem>
        <br/><br/>
        <GridItem xs={12} md={6}>
          <span className={classes.font1}> Consignee:</span> {airwayData.consignee_obj.name}
        </GridItem>
        <GridItem xs={12} md={6}>
          <span className={classes.font1}> Out for Delivery:</span> {(airwayData.hawb.ofd_date !== null) ? `${moment(airwayData.hawb.ofd_date).utc().format('MM/DD/YYYY')} @ ${airwayData.hawb.ofd_time}` : ''}
        </GridItem>
        <GridItem xs={12} md={6}>
          <span className={classes.font1}> Address:</span> {airwayData.consignee_obj.address1}
        </GridItem>
        <GridItem xs={12} md={6}>
          <span className={classes.font1}> Delivered:</span> {(airwayData.hawb.pod_date !== null) ? `${moment(airwayData.hawb.pod_date).utc().format('MM/DD/YYYY')} @ ${airwayData.hawb.pod_time}` : ''}
        </GridItem>
        <GridItem xs={12} md={6}>
          <span className={classes.font1}> City State Zip:</span> {airwayData.consignee_obj.city+' '+airwayData.consignee_obj.state+', '+airwayData.consignee_obj.zip}
        </GridItem>
        <GridItem xs={12} md={6}>
          <span className={classes.font1}> Signed By:</span> {airwayData.hawb.pod_name}
        </GridItem>
        
      </GridContainer>
      </>
  );
}
