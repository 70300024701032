import React from "react";
import { Redirect } from 'react-router-dom';

// react component for creating dynamic tables
import GridContainer from "components/Grid/GridContainer.js";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useCookies } from 'react-cookie';

import styles from "assets/jss/material-dashboard-pro-react/views/allShipmentsStyle.js";
const useStyles = makeStyles(styles);


export default function LogoutView(props) {
    const [loginCookies, setLoginCookies,removeLoginCookies] = useCookies();  

    React.useEffect(() => {
      removeLoginCookies('token',{path:'/'});
    });

    return(
      <GridContainer>
      </GridContainer>
    )
}
