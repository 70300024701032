import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";
import Code from "@material-ui/icons/Code";
import Group from "@material-ui/icons/Group";
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";
import Check from "@material-ui/icons/Check";
import AddAlert from "@material-ui/icons/AddAlert";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import BootstrapInput from "components/BootstrapInput/BootstrapInput.js";
import DetailViewNoBoxes from "views/DetailViewNoBoxes/DetailViewNoBoxes.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import axios from "axios";

import ReactToPrint from 'react-to-print';
import styles from "assets/jss/material-dashboard-pro-react/views/searchHAWBPageStyle";

const useStyles = makeStyles(styles);

const NODE_URL = (process.env.REACT_APP_NODE_BASE_URL) ? process.env.REACT_APP_NODE_BASE_URL : '';

export default function SearchHAWB(props) {
  const [open, setOpen] = React.useState(false);
  const [messageInfo, setMessageInfo] = React.useState(undefined);
  const [btnValue, setbtnValue] = React.useState("Search");
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [airwayData, setAirwayData] = React.useState();
  const [isLoading, setLoading] = React.useState(false);
  const componentRef = React.useRef();

  let defSearchValue = '';
  if (props.location.search.indexOf('?tracking=') >= 0){
    defSearchValue = props.location.search.split('?tracking=').join('');
  }
  const [searchInput,setSearchInput] = React.useState(defSearchValue);

  setTimeout(function() {
    setCardAnimation("");
  }, 700);
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    setOpen(false);
  };

  React.useEffect(()=>{
    const timer = setTimeout(() => setOpen(false), 4000);
    return () => clearTimeout(timer);
  });

  React.useEffect(()=>{
    if (defSearchValue != '') search();
  },[]);

  const classes = useStyles();
  const search = ()=>{
    setbtnValue("Searching...");
    setLoading(true);
    axios({
      url: NODE_URL + `/airway/search/bill?hawb=${searchInput}&key=nrportal`,
      method: 'get',
      
    })
    .then((res) => {
      if (res.data.success) {
        setAirwayData(res.data.results)
      }
      else{
        setOpen(true);
        setMessageInfo({message: res.data.message});
      }
      setbtnValue("Search");
      setLoading(false);
    })
  };

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={10}>
          <Card className={classes.cardSignup}>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Check />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Search by Airway Bill</h4>
            </CardHeader>

            <CardBody>
            <GridContainer>

              <GridItem xs={12} sm={12} md={5}>
                <BootstrapInput
                  label={'Airway Bill #'}
                  value={searchInput}
                  onChange={(e)=>setSearchInput(e.target.value)}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
              <Button
                color="primary"
                style={{marginTop:'32px'}}
                disabled={isLoading}
                onClick={()=>{search()}}
              >{btnValue}</Button>
              </GridItem>
            </GridContainer>
            {airwayData &&
            <div>
              <div 
                className={classes.detailDiv}
                ref={componentRef}
              >         
                <DetailViewNoBoxes 
                  classes = {classes}
                  airwayData = {airwayData}
                />
              </div>
                <ReactToPrint
                trigger={() => <Button style={{marginBottom:'20px', marginLeft:'5%'}} color="success" size="sm">Print this</Button>}
                content={() => componentRef.current}
				bodyClass={'RemoveOverflows'}
                />
            </div>
            }  
            </CardBody>
          </Card>
        </GridItem>
        <Snackbar
          place="tc"
          open={open}
          autoHideDuration={4000}
          color={'danger'}
          icon={AddAlert}
          onClose={handleClose}
          close
          closeNotification={handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{messageInfo ? messageInfo.message : undefined}</span>}
        />
      </GridContainer>
    </div>
  );
}
