import React, { useState, useEffect } from "react";
import { useCookies } from 'react-cookie';

export default function ProfileView() {
    const [loginCookies, setLoginCookies,removeLoginCookies] = useCookies();  

    useEffect(()=>{
        window.location = 'https://natroute.com/sign-up-page-for-electronic-pod-emails/?customer_no=' + loginCookies.customerId;
    }); 

    return (
        <></>
    )
}