import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import CloseIcon from "@material-ui/icons/Close";
import AddAlert from "@material-ui/icons/AddAlert";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import useGlobal from "store/index.js"

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import logo from "assets/img/natroute_logo.svg";
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useHistory } from "react-router-dom";

const NODE_URL = (process.env.REACT_APP_NODE_BASE_URL) ? process.env.REACT_APP_NODE_BASE_URL : '';

const useStyles = makeStyles(styles);

export default function LoginPage(props) {
  const [open, setOpen] = React.useState(false);
  const [messageInfo, setMessageInfo] = React.useState(undefined);
  const [loginValue, setloginValue] = React.useState("LOGIN");
  const [isLoading, setLoading] = React.useState(false);
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [loginCredentials, setLoginCredentials] = React.useState({customerId:'',password:''});
  const [globalState, globalActions] = useGlobal();
  const [loginCookies, setLoginCookies,removeLoginCookies] = useCookies({token:''});
  const history = useHistory();
  const buttonRef = React.useRef(null);

  setTimeout(function() {
    setCardAnimation("");
  }, 700);

  const classes = useStyles();

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      buttonRef.current.click();
    }
  }

  const loginClick = () => {
    let errors = [];
    setloginValue("LOGGING-IN...");
    setLoading(true);

    if (loginCredentials.customerId === '') {
      setMessageInfo({message: 'Email is required.'});
      errors.push('Email is required.');
      setloginValue("LOGIN");
      setLoading(false);
      setOpen(true);
    }

    else if (loginCredentials.password === '') {
      setMessageInfo({message: 'Password is required.'});
      errors.push('Password is required.');
      setloginValue("LOGIN");
      setLoading(false);
      setOpen(true);
    }

    /*else if (loginCredentials.password.length < 5) {
      setMessageInfo({message: 'Password need to be at least 5 characters.'});
      errors.push('Password need to be at least 5 characters.')
      setloginValue("LOGIN");
      setLoading(false);
      setOpen(true);
    }*/

    if (errors.length) {
      setloginValue("LOGIN");
      setLoading(false);
      setOpen(true);
    }
    else {
      setloginValue("LOGGING-IN...");
      setLoading(true);

      axios({
          url: NODE_URL + '/token',
          method: 'post',
          data: loginCredentials
        })
        .then((res) => {
          if (res.data.success){
            setLoginCookies('token', res.data.token, {path:'/', maxAge: 31536000});
            setLoginCookies('customerId', loginCredentials.customerId, {path:'/', maxAge: 31536000});

            //globalActions.updateGlobalVars(loginCredentials.customerId,'customerId');
            if (props.location.search != '' 
              && props.location.search.indexOf('?redirect=') == 0 
              && props.location.search.indexOf('logout') < 0){
              let url = props.location.search.split('?redirect=').join('')
              history.push(url)
            }
            else{
              history.push("/admin/track");
            }
            
          } else {
            setOpen(true);
            setMessageInfo({message: res.data.message});
            setloginValue("LOGIN");
            setLoading(false);
          }
        })
        .catch(err => {
          setOpen(true);
          setMessageInfo({message: 'An error has occurred.'});
          setloginValue("LOGIN");
          setLoading(false);
        });
    }
  }

  React.useEffect(()=>{
    const timer = setTimeout(() => setOpen(false), 4000);
    return () => clearTimeout(timer);
  });

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form>
          <div className={classes.flex}>
            <Card className={classes[cardAnimaton]} >
            <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="white"
                style={{marginBottom: "0px"}}
              > 
              Want to learn more about how we can assist you? Visit <a href="https://natroute.com/">https://natroute.com/</a>.
              </CardHeader>
            </Card>
          </div>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="green"
              > 
                <h4 className={classes.cardTitle}>User Login</h4>
              </CardHeader>
              <CardBody>
              <img className={classes.natRouteLogo} src={logo} alt={"National Routing Logo"}/>
                <CustomInput
                  labelText="Username"
                  id="customerId"
                  formControlProps={{
                    fullWidth: true
                  }}
                  value={loginCredentials.customerId}
                  onChange={e=>{
                    e.persist();
                    setLoginCredentials(state=>({...state,customerId:e.target.value}));
                  }}
                  onKeyPress={(e) => handleKeyPress(e)}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Face className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    )
                  }}
                />
                
                <CustomInput
                  labelText="Password"
                  id="password"
                  formControlProps={{
                    fullWidth: true
                  }}
                  onChange={e=>{
                    e.persist();
                    setLoginCredentials(state=>({...state,password:e.target.value}));
                  }}
                  onKeyPress={(e) => handleKeyPress(e)}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off"
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button color="rose" simple size="lg" style={{backgroundColor:'#5a467d',color:"#fff",fontWeight: 'bold',}} block
                  onClick={()=>loginClick()}
                  disabled={isLoading}
                  ref={buttonRef}
                >
                  {loginValue}
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
      <Snackbar
        place="tc"
        open={open}
        color={'danger'}
        icon={AddAlert}
        close
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{messageInfo ? messageInfo.message : undefined}</span>}
        closeNotification={() => setOpen(false)}
      />
    </div>
  );
}
