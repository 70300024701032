import {
  container,
  cardTitle,
  whiteColor,
  grayColor
} from "assets/jss/material-dashboard-pro-react.js";
import { fontWeight } from "@material-ui/system";

const allShipmentsStyle = theme => ({
  boxList: {
    width: '100%',
    position: 'relative',
    overflow: 'auto',
    maxHeight: 200,
    maxWidth: 700,
    margin: '15px'
  },
  boxListNew: {
    width: '100%',
    position: 'relative',
    overflow: 'auto',
    maxHeight: 200,
    maxWidth: 950,
    margin: '15px'
  },
  wrapper:{
    backgroundColor:'rgba(224,224,224,0.5)',
    paddingBottom: '40px',

  },
  detailsPane:{
    paddingTop: '40px',
    paddingLeft:'30px',
    lineHeight: '1.7rem',
    color: '#5a467d'
  },
  titleDetailPane: {
      color: '#5a467d',
      fontSize: '21px',
      fontWeight: 600
    },
  font1: {
    color:'#467058',
	fontWeight: 600
  },
  
});
  

export default allShipmentsStyle;
