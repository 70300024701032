import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import AccountBox from "@material-ui/icons/AccountBox";
import Contacts from "@material-ui/icons/Contacts";
import AddAlert from "@material-ui/icons/AddAlert";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import axios from "axios";
import { useCookies } from 'react-cookie';

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const useStyles = makeStyles(styles);

const NODE_URL = (process.env.REACT_APP_NODE_BASE_URL) ? process.env.REACT_APP_NODE_BASE_URL : '';

export default function ProfileView() {
  const [emailField, setEmailField] = useState({error: false, helperText: '', value: ''});
  const [nameField, setNameField] = useState({value: ''});
  const [buttonProfile, setButtonProfile] = useState({disabled: false});
  const [passwordField, setPasswordField] = useState({error: false, helperText: '', value: ''});
  const [cpasswordField, setCpasswordField] = useState({error: false, helperText: '', value: ''});
  const [buttonPassword, setButtonPassword] = useState({disabled: false});
  const [loginCookies, setLoginCookies,removeLoginCookies] = useCookies();  
  const [alert, setAlert] = useState({open: false, color: 'danger', icon: AddAlert, message: ''});

  const classes = useStyles();

  const changePassword =() =>{
    axios({
      url: NODE_URL + `/user/current_user/change_password`,
      method: 'post',
      data: {password: passwordField.value},
      headers: {
        'x-access-token': loginCookies.token
      }
    })
    .then((res) => {
      if (res.data.success){
        setAlert({open: true, color: 'success', icon: AddAlert, message: res.data.message});
        setPasswordField({value: '', error: false, helperText: ''})
        setCpasswordField({value: '', error: false, helperText: ''})
      }
      else{
        setAlert({open: true, color: 'danger', icon: AddAlert, message: res.data.message});
      }
    })
    .catch(err =>{
      
      setAlert({open: true, color: 'danger', icon: AddAlert, message: 'Connection error. Please reload the page.'})
    });
  }

  const updateProfile =() =>{
    axios({
      url: NODE_URL + `/user/current_user/profile`,
      method: 'post',
      data: {email: emailField.value, name: nameField.value},
      headers: {
        'x-access-token': loginCookies.token
      }
    })
    .then((res) => {
      if (res.data.success){
        setAlert({open: true, color: 'success', icon: AddAlert, message: res.data.message});
      }
      else{
        setAlert({open: true, color: 'danger', icon: AddAlert, message: res.data.message});
      }
    })
    .catch(err =>{
      
      setAlert({open: true, color: 'danger', icon: AddAlert, message: 'Connection error. Please reload the page.'})
    });
  }

  const validateField =(id, value) =>{
    value = value.trim();
    switch (id){
      case 'email':
        let emailValid = value == '' ? true : value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        setEmailField({value: value, error: emailValid ? false : true, helperText: emailValid ? '' : 'Invalid Email format.'})
        setButtonProfile({disabled: emailValid ? false : true})
        break;
      case 'password':
        let passwordValid = (value.length >= 5) ? true : false;
        setPasswordField({value: value, error: passwordValid ? false : true, helperText: passwordValid ? '' : 'Password need to be at least 5 characters.'})
        setButtonPassword({disabled: passwordValid && !cpasswordField.error ? false : true})
        break;
      case 'cpassword':
        let cpasswordValid = (value.length >= 5) ? true : false;
        if (cpasswordValid){
          setCpasswordField({value: value, error: (passwordField.value == value) ? false : true, helperText: (passwordField.value == value) ? '' : 'Passwords do not match.'})
          setButtonPassword({disabled: (passwordField.value == value && !passwordField.error) ? false : true})      
        }
        else{
          setCpasswordField({value: value, error: true, helperText: 'Password need to be at least 5 characters.'})
          setButtonPassword({disabled: true})          
        }
        break;
    }
  };

  useEffect(()=>{
    const timer = setTimeout(() => setAlert({color: alert.color, open: false}), 4000);
    return () => clearTimeout(timer);
  });

  useEffect(()=>{
    axios({
      url: NODE_URL + `/user/current_user`,
      method: 'get',
      headers: {
        'x-access-token': loginCookies.token
      }
    })
    .then((res) => {
      
      if (res.data.success){
        setEmailField({value: res.data.results.email, error: false, helperText: ''});
        setNameField({value: res.data.results.name});
      }
      else{
        setAlert({open: true, color: 'danger', icon: AddAlert, message: 'An Error has occurred.'});
      }
    })
    .catch(err =>{
      
      setAlert({open: true, color: 'danger', icon: AddAlert, message: 'Connection error. Please reload the page.'})
    });
  },[]);

  return (
    <div>
    <GridContainer>
      <GridItem xs={12} sm={12} md={6}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <AccountBox />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Update Profile</h4>
          </CardHeader>
          <CardBody>
            <form>
            <CustomInput
                labelText="Name"
                id="name"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: "name",
                }}
                value={nameField.value}
                onChange={(e)=>setNameField({value: e.target.value})}
                helperText=''
              />
              <CustomInput
                labelText="Email adress"
                id="email"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: "email"
                }}
                value={emailField.value}
                onChange={(e)=>validateField(e.target.id, e.target.value)}
                error={emailField.error}
                helperText={emailField.helperText}
              />
              <Button color="primary" 
              onClick={updateProfile}
              disabled={buttonProfile.disabled}
              >Update</Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={6}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Contacts />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Change Password</h4>
          </CardHeader>
          <CardBody>
            <form>
            <CustomInput
                labelText="Password"
                id="password"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: "password",
                  autoComplete: "off"
                }}
                value={passwordField.value}
                onChange={(e)=>validateField(e.target.id, e.target.value)}
                helperText={passwordField.helperText}
                error={passwordField.error}
              />
            <CustomInput
                labelText="Cofirm Password"
                id="cpassword"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: "password",
                  autoComplete: "off"
                }}
                value={cpasswordField.value}
                onChange={(e)=>validateField(e.target.id, e.target.value)}
                helperText={cpasswordField.helperText}
                error={cpasswordField.error}
              />
              <Button color="primary" 
              onClick={changePassword}
              disabled={buttonPassword.disabled}
              >Change Password</Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
    <Snackbar
      place="tc"
      open={alert.open}
      color={alert.color ? alert.color : 'danger'}
      icon={alert.icon}
      close
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      message={<span id="message-id">{alert.message}</span>}
      closeNotification={() => setAlert({open:false, color: alert.color})}
    />
    </div>
  );
}
