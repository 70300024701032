import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import CustomBootstrapInput from 'components/BootstrapInput/BootstrapInput'
import InputAdornment from '@material-ui/core/InputAdornment';
import CalendarToday from "@material-ui/icons/CalendarToday";
import IconButton from '@material-ui/core/IconButton';
import { KeyboardDatePicker, DatePicker } from "@material-ui/pickers";
import moment from 'moment';

export default function CustomDatePicker(props) {
  const [pickerState,setPickerState] = React.useState(false);
  const CustomInput = (props)=>{
    return(
    <CustomBootstrapInput
        onFocus = {()=>setPickerState(true)}
        label={props.label}
        value={props.value}
        onChange={props.onChange}
        endAdornment={<InputAdornment position="end">
                        <IconButton onClick={()=>setPickerState(true)}>
                        <CalendarToday/>
                        </IconButton>
                      </InputAdornment>}
        />
    );
  }

  return (
      <DatePicker 
        TextFieldComponent={CustomInput}
        open={pickerState}
        value={props.value}
        label={props.label}
        onAccept={()=>setPickerState(false)}
        onClose={()=>setPickerState(false)}
        onChange={props.onChange}
        format={props.format ? props.format : ""}
        />

  );
}