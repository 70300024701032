import {
  container,
  cardTitle,
  blackColor,
  hexToRgb,
  grayColor
} from "assets/jss/material-dashboard-pro-react.js";


const registerPageStyle = {
  
  container: {
    ...container,
    position: "relative",
    zIndex: "3",
    // paddingTop: "23vh"
  },
  cardSignup: {
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 6px 30px 5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.12), 0 8px 10px -5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.2)",
    marginBottom: "100px",
    marginTop: "15vh"
  },
  cardIconTitle:{
    color: 'black'
  },
  detailsPane:{
    paddingTop: '40px',
    paddingLeft:'30px',
    lineHeight: '1.7rem',
    color: '#5a467d'
  },
  titleDetailPane: {
      color: '#5a467d',
      fontSize: '21px',
      fontWeight: 600
    },
  font1: {
    color:'#467058',
  },
  detailDiv:{
    margin:'5%',
    lineHeight: '1.8rem'
  }
};

export default registerPageStyle;
