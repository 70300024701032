import React from "react";
import { Redirect } from 'react-router-dom';

// react component for creating dynamic tables

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomBootstrapInput from "components/BootstrapInput/BootstrapInput.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomSelect from "components/CustomSelect/CustomSelect";
import { KeyboardDatePicker, DatePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker";
import useGlobal from "store/index.js"
import moment from 'moment';
import FileDownload from "js-file-download";

import axios from "axios";
import Paper from "@material-ui/core/Paper";
import { useCookies } from 'react-cookie';

import styles from "assets/jss/material-dashboard-pro-react/views/allShipmentsStyle.js";
const useStyles = makeStyles(styles);

const NODE_URL = (process.env.REACT_APP_NODE_BASE_URL) ? process.env.REACT_APP_NODE_BASE_URL : '';

export default function SearchView(props) {
    const USstates = [
    {value: "All", label: "All States"},
    {value: "AL", label: "Alabama"},
    {value: "AK", label: "Alaska"},
    {value: "AZ", label: "Arizona"},
    {value: "AR", label: "Arkansas"},
    {value: "CA", label: "California"},
    {value: "CO", label: "Colorado"},
    {value: "CT", label: "Connecticut"},
    {value: "DE", label: "Delaware"},
    {value: "DC", label: "District of Columbia"},
    {value: "FL", label: "Florida"},
    {value: "GA", label: "Georgia"},
    {value: "HI", label: "Hawaii"},
    {value: "ID", label: "Idaho"},
    {value: "IL", label: "Illinois"},
    {value: "IN", label: "Indiana"},
    {value: "IA", label: "Iowa"},
    {value: "KS", label: "Kansas"},
    {value: "KY", label: "Kentucky"},
    {value: "LA", label: "Louisiana"},
    {value: "ME", label: "Maine"},
    {value: "MD", label: "Maryland"},
    {value: "MA", label: "Massachusetts"},
    {value: "MI", label: "Michigan"},
    {value: "MN", label: "Minnesota"},
    {value: "MS", label: "Mississippi"},
    {value: "MO", label: "Missouri"},
    {value: "MT", label: "Montana"},
    {value: "NE", label: "Nebraska"},
    {value: "NV", label: "Nevada"},
    {value: "NH", label: "New Hampshire"},
    {value: "NJ", label: "New Jersey"},
    {value: "NM", label: "New Mexico"},
    {value: "NY", label: "New York"},
    {value: "NC", label: "North Carolina"},
    {value: "ND", label: "North Dakota"},
    {value: "OH", label: "Ohio"},
    {value: "OK", label: "Oklahoma"},
    {value: "OR", label: "Oregon"},
    {value: "PA", label: "Pennsylvania"},
    {value: "RI", label: "Rhode Island"},
    {value: "SC", label: "South Carolina"},
    {value: "SD", label: "South Dakota"},
    {value: "TN", label: "Tennessee"},
    {value: "TX", label: "Texas"},
    {value: "UT", label: "Utah"},
    {value: "VT", label: "Vermont"},
    {value: "VA", label: "Virginia"},
    {value: "WA", label: "Washington"},
    {value: "WV", label: "West Virginia"},
    {value: "WI", label: "Wisconsin"},
    {value: "WY", label: "Wyoming"}];

    const ShipMethods = [
      {value: "All", label: "All"},
      {value: "1D", label: "1 Day"},
      {value: "2D", label: "2 Day"},
      {value: "3D", label: "3 Day"},
      {value: "4D", label: "4 Day"},
      {value: "DEF", label: "Deferred"}
    ];

    const [globalState, globalActions] = useGlobal();
    const [tableData,setTableData] = React.useState({data:[]});
    const [loginCookies, setLoginCookies,removeLoginCookies] = useCookies();  
    const [redirectState,setRedirectState] = React.useState(false);
    const [HAWBState,setHAWBState] = React.useState('');
    const [searchInput, setSearchInput] = 
      React.useState({
        hawb:'',
        custpo:'',
        shipnum:'',
        custbox:'',
        departure:'All',
        destination:'All',
        startdate: moment().subtract(1, 'months').utc(),
        enddate: moment().utc(),
        shipmethod: 'All',
        delivered: 'All',
        excel: 'No'
      });

    const [btnValue, setbtnValue] = React.useState("Search");
    const [isLoading, setLoading] = React.useState(false);

    const SearchClick = () => {
      let csv = '';
      if (searchInput.excel==='Yes'){csv = '/csv'};

      const data = {
        ['HAWB#']: searchInput.hawb,
        ['Session ID']: searchInput.custpo,
        ['Shipper Ref#']: searchInput.shipnum,
        ['Diesel Box Number']: searchInput.custbox,
        ['ORIGIN']: searchInput.departure === 'All' ? '' : searchInput.departure,
        ['Destination']: searchInput.destination === 'All' ? '' : searchInput.destination,
        ['Start Date']:  moment(searchInput.startdate['_d']).utc().format('MM/DD/YYYY'),
        ['End Date']: moment(searchInput.enddate['_d']).utc().format('MM/DD/YYYY'), 
        ['Shipping Method']: searchInput.shipmethod==='All'? '':searchInput.shipmethod,
        ['POD Date']: searchInput.delivered==='All'? '':searchInput.delivered,
        ['Excel Format']: searchInput.excel,
        
      };
      
      globalActions.updateGlobalVars(data,'searchParams');

      setbtnValue("Searching...");
      setLoading(true);
      
      if (searchInput.excel==='Yes'){
        axios({
          url: NODE_URL + `/airway/search${csv}`,
          method: 'post',
          headers: {
            'x-access-token': loginCookies.token
          },
          data: data,
          responseType: 'arraybuffer'
        })
        .then((res) => {
          //FileDownload(res.data, `${loginCookies.customerId}-${moment().format('YYYYMMDD-hhmm')}.csv`)
          FileDownload(res.data, `${loginCookies.customerId}-${moment().format('YYYYMMDD-hhmm')}.xlsx`)
          setLoading(false);
          setbtnValue("Search");
        });
      } else {
        setRedirectState(true);
      }
   /*   axios({
          url: NODE_URL + `/airway/search${csv}`,
          method: 'post',
          headers: {
            'x-access-token': loginCookies.token
          },
          data: data,
        })
        .then((res) => {
          //console.log(res);
          globalActions.updateGlobalVars(res.data,'data');
          searchInput.excel==='Yes'? FileDownload(res.data, `${loginCookies.customerId}-${moment().format('YYYYMMDD-hhmm')}.csv`):setRedirectState(true);
        })
        */

    };
    React.useEffect(() => {
      
    });

    const classes = useStyles();
    const handleSearchInputChange = (event, data) => {
      //console.log(moment(event['_d']).format('MM/DD/YYYY'))
      let input = {
        ...searchInput
      }
      input[data] = event;
      setSearchInput(input)
    }
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
    <GridContainer>
      {redirectState&&
      <Redirect to='/admin/track-shipments' />
      }
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon> 
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Track{globalState.counter} Shipment</h4>
          </CardHeader>
          <CardBody>
              <GridContainer>
                <GridItem xs={6} lg={3}>
                  <CustomBootstrapInput 
                      label={'Airway Bill #'}
                      onChange={e=>handleSearchInputChange(e.target.value,'hawb')}
                  />
                </GridItem>
                <GridItem xs={6} lg={3}>
                  <CustomBootstrapInput 
                      label={'Cust PO #'}
                      onChange={e=>handleSearchInputChange(e.target.value,'custpo')}
                  />
                </GridItem>
                <GridItem xs={6} lg={3}>
                  <CustomBootstrapInput 
                      label={'Shipper #'}
                      onChange={e=>handleSearchInputChange(e.target.value,'shipnum')}
                  />
                </GridItem>
                <GridItem xs={6} lg={3}>
                  <CustomBootstrapInput 
                      label={'Cust Box #'}
                      onChange={e=>handleSearchInputChange(e.target.value,'custbox')}
                  />
                </GridItem>
                <GridItem xs={6} lg={3}>
                  <CustomSelect
                      label={'Departure:'}
                      dropdownList = {USstates}
                      value={searchInput.departure}
                      onChange={e=>handleSearchInputChange(e.target.value,'departure')}

                  />
                </GridItem>
                <GridItem xs={6} lg={3}>
                  <CustomSelect
                      label={'Destination:'}
                      dropdownList = {USstates}
                      value={searchInput.destination}
                      onChange={e=>handleSearchInputChange(e.target.value,'destination')}
                  />
                </GridItem>
                <GridItem xs={6} lg={3}>
                  <CustomDatePicker
                    label={'Start Date:'}
                    value={searchInput.startdate}
                    onChange={e=>handleSearchInputChange(e,'startdate')}
                    format={'MMMM Do, YYYY'}
                  />
                </GridItem>
                <GridItem xs={6} lg={3}>
                  <CustomDatePicker
                    label={'End Date:'}
                    value={searchInput.enddate}
                    onChange={e=>handleSearchInputChange(e,'enddate')}
                    format={'MMMM Do, YYYY'}
                  />
                </GridItem>
                <GridItem xs={6} lg={3}>
                  <CustomSelect
                      label={'Ship Method:'}
                      dropdownList = {ShipMethods}
                      value={searchInput.shipmethod}
                      onChange={e=>handleSearchInputChange(e.target.value,'shipmethod')}
                  />
                </GridItem>
                <GridItem xs={6} lg={3}>
                  <CustomSelect
                      label={'Delivered:'}
                      dropdownList = {['Yes','No','All']}
                      onChange={e=>handleSearchInputChange(e.target.value,'delivered')}
                      value={searchInput.delivered}
                  />
                </GridItem>
                <GridItem xs={6} lg={3}>
                  <CustomSelect
                      label={'Excel Format:'}
                      dropdownList = {['Yes','No']}
                      onChange={e=>handleSearchInputChange(e.target.value,'excel')}
                      value={searchInput.excel}
                  />
                </GridItem>
              </GridContainer>
            <Button
                color="primary"
                disabled={isLoading}
                onClick={()=>SearchClick()}
                style={{float:'left'}}
            >{btnValue}
            </Button>
            <div>
            </div>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
    </MuiPickersUtilsProvider>

  );
}
