import React from "react";
import useGlobalHook from "use-global-hook";

import * as actions from "actions/index.js";

const initialState = {
  data:undefined,
  customerId:undefined,
  searchParams:undefined
};

const useGlobal = useGlobalHook(React, initialState, actions);

export default useGlobal;
