import React from "react";
import { useCookies } from 'react-cookie';

import { makeStyles } from "@material-ui/core/styles";

export default function CustomProfile(props) {
  const [loginCookies, setLoginCookies,removeLoginCookies] = useCookies();  

  return (
    <span>LOGIN ID: {loginCookies.customerId}</span>
  );
}